<template>
  <div class="addrecord-trip-dia">
    <el-form
      ref="addRecordTripDiaFrom"
      :model="addRecordTripDiaFrom"
      label-position="left"
      label-width="100px"
      :rules="addTripRules"
      class="demo-form dialog-form"
    >
      <el-form-item label="日期:" prop="workDay">
        <el-date-picker
          v-model="addRecordTripDiaFrom.workDay"
          type="date"
          placeholder="选择日期"
          value-format="yyyy-MM-dd"
          :picker-options="datePickerOptions"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="线路:" prop="companyId">
        <el-select
          v-model="addRecordTripDiaFrom.companyId"
          placeholder="请选择"
        >
          <el-option
            v-for="item in busLineList"
            :key="item.companyId"
            :label="item.companyName"
            :value="item.companyId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="车辆：" prop="vehicleNo">
        <el-select
          v-model="addRecordTripDiaFrom.vehicleNo"
          :filter-method="filterMethodFun"
          filterable
          placeholder="请选择车辆"
        >
          <el-option
            v-for="item in vehicleOptions"
            :key="item.vehicleNo"
            :label="item.cph"
            :value="item.vehicleNo"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="司机:" prop="realityDriver">
        <el-select
          v-model="addRecordTripDiaFrom.realityDriver"
          :filter-method="vehicleMethodFun"
          filterable
          placeholder="请选择司机"
        >
          <el-option
            v-for="item in driversOptions"
            :key="item.credentialsNo"
            :label="item.driverName"
            :value="item.credentialsNo"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="实际发车:" prop="leaveTime">
        <el-date-picker
          v-model="addRecordTripDiaFrom.leaveTime"
          type="datetime"
          placeholder="选择时间"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="实际到达:" prop="arriveTime">
        <el-date-picker
          v-model="addRecordTripDiaFrom.arriveTime"
          type="datetime"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="选择时间"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="驾驶状态：" prop="drivingState">
        <el-select
          v-model="addRecordTripDiaFrom.drivingState"
          placeholder="请选择"
        >
          <el-option
            v-for="item in drivingStateList"
            :label="item.content"
            :value="item.value"
            :key="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="是否计趟：" prop="times">
        <el-select
          v-model="addRecordTripDiaFrom.times"
          placeholder="请选择"
        >
          <el-option
            v-for="item in timesList"
            :label="item.stateValue"
            :value="item.stateCode"
            :key="item.stateCode"
          ></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="变更说明：" prop="natureState">
        <el-select
          v-model="addRecordTripDiaFrom.natureState"
          placeholder="请选择"
        >
          <el-option
            v-for="item in natureStateList"
            :label="item.stateValue"
            :value="item.stateCode"
            :key="item.stateCode"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="备注：" prop="remark">
        <el-input
          type="textarea"
          :rows="4"
          v-model="addRecordTripDiaFrom.remark"
        >
        </el-input>
      </el-form-item>
      <div class="buttonGroup text-right tabButtonGroup">
        <el-button type="default" size="small" @click="cancleTripDia"
          >取 消</el-button
        >
        <el-button type="primary" size="small" @click="submitTripDia"
          >确 定</el-button
        >
      </div>
    </el-form>
  </div>
</template>
<script>
import { formatDay } from "@/common/utils/index";
import { getDriverByline, getBusByline } from "@/api/lib/bus-api.js";
export default {
  props: {
    busLineList: {
      type: Array,
      default: () => []
    },
    drivingStateList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      addRecordTripDiaFrom: {
        workDay: "",
        companyId: "",
        vehicleNo: "",
        realityDriver: "",
        leaveTime: "",
        arriveTime: "",
        drivingState: "",
        // times:'',
        natureState: "",
        remark: ""
      },
      driversOptions: [],
      vehicleOptions: [],
      // timesList:[
      //   {stateValue:'计趟次',stateCode:0.5},
      //   {stateValue:'不计趟次',stateCode:0}
      // ],
      natureStateList: [
        { stateValue: "加油", stateCode: 3 },
        { stateValue: "加气", stateCode: 4 },
        { stateValue: "充电", stateCode: 5 },
        { stateValue: "维修", stateCode: 6 },
        { stateValue: "包车", stateCode: 7 },
        { stateValue: "其他", stateCode: 128 }
      ],
      addTripRules: {
        companyId: [{ required: true, message: "不能为空", trigger: "change" }],
        workDay: [{ required: true, message: "不能为空", trigger: "change" }],
        vehicleNo: [{ required: true, message: "不能为空", trigger: "change" }],
        realityDriver: [
          { required: true, message: "不能为空", trigger: "change" }
        ],
        leaveTime: [{ required: true, message: "不能为空", trigger: "change" }],
        arriveTime: [
          { required: true, message: "不能为空", trigger: "change" }
        ],
        drivingState: [
          { required: true, message: "不能为空", trigger: "change" }
        ],
        // times:[{ required: true, message: "不能为空", trigger: "change" }],
        natureState: [
          { required: true, message: "不能为空", trigger: "change" }
        ]
      },
      datePickerOptions: {
        disabledDate(time) {
          return (
            time >
            new Date(new Date(`${formatDay(new Date())} 00:00:00`).getTime())
          );
        }
      }
    };
  },
  methods: {
    //模糊查询司机
    vehicleMethodFun(text) {
      getDriverByline({ keyWord: text }).then(res => {
        if (res.code == 1000) {
          this.driversOptions = res.data;
        }
        // else if(res.code===1100){
        //   this.$message.warning(res.msg)
        // }
      });
    },
    // 车辆模糊查询
    filterMethodFun(text) {
      getBusByline({ keyWord: text }).then(res => {
        if (res.code == 1000) {
          this.vehicleOptions = res.data;
        }
      });
    },
    cancleTripDia() {
      this.$emit("closeDia", false);
    },
    submitTripDia() {
      this.$refs.addRecordTripDiaFrom.validate(valid => {
        if (valid) {
          this.$emit("submitTripDiaFrom", this.addRecordTripDiaFrom);
        } else {
          return false;
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.addrecord-trip-dia {
}
</style>
