var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "addrecord-trip-dia" },
    [
      _c(
        "el-form",
        {
          ref: "addRecordTripDiaFrom",
          staticClass: "demo-form dialog-form",
          attrs: {
            model: _vm.addRecordTripDiaFrom,
            "label-position": "left",
            "label-width": "100px",
            rules: _vm.addTripRules
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "日期:", prop: "workDay" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "date",
                  placeholder: "选择日期",
                  "value-format": "yyyy-MM-dd",
                  "picker-options": _vm.datePickerOptions
                },
                model: {
                  value: _vm.addRecordTripDiaFrom.workDay,
                  callback: function($$v) {
                    _vm.$set(_vm.addRecordTripDiaFrom, "workDay", $$v)
                  },
                  expression: "addRecordTripDiaFrom.workDay"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "线路:", prop: "companyId" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.addRecordTripDiaFrom.companyId,
                    callback: function($$v) {
                      _vm.$set(_vm.addRecordTripDiaFrom, "companyId", $$v)
                    },
                    expression: "addRecordTripDiaFrom.companyId"
                  }
                },
                _vm._l(_vm.busLineList, function(item) {
                  return _c("el-option", {
                    key: item.companyId,
                    attrs: { label: item.companyName, value: item.companyId }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "车辆：", prop: "vehicleNo" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    "filter-method": _vm.filterMethodFun,
                    filterable: "",
                    placeholder: "请选择车辆"
                  },
                  model: {
                    value: _vm.addRecordTripDiaFrom.vehicleNo,
                    callback: function($$v) {
                      _vm.$set(_vm.addRecordTripDiaFrom, "vehicleNo", $$v)
                    },
                    expression: "addRecordTripDiaFrom.vehicleNo"
                  }
                },
                _vm._l(_vm.vehicleOptions, function(item) {
                  return _c("el-option", {
                    key: item.vehicleNo,
                    attrs: { label: item.cph, value: item.vehicleNo }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "司机:", prop: "realityDriver" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    "filter-method": _vm.vehicleMethodFun,
                    filterable: "",
                    placeholder: "请选择司机"
                  },
                  model: {
                    value: _vm.addRecordTripDiaFrom.realityDriver,
                    callback: function($$v) {
                      _vm.$set(_vm.addRecordTripDiaFrom, "realityDriver", $$v)
                    },
                    expression: "addRecordTripDiaFrom.realityDriver"
                  }
                },
                _vm._l(_vm.driversOptions, function(item) {
                  return _c("el-option", {
                    key: item.credentialsNo,
                    attrs: { label: item.driverName, value: item.credentialsNo }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "实际发车:", prop: "leaveTime" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "datetime",
                  placeholder: "选择时间",
                  format: "yyyy-MM-dd HH:mm:ss",
                  "value-format": "yyyy-MM-dd HH:mm:ss"
                },
                model: {
                  value: _vm.addRecordTripDiaFrom.leaveTime,
                  callback: function($$v) {
                    _vm.$set(_vm.addRecordTripDiaFrom, "leaveTime", $$v)
                  },
                  expression: "addRecordTripDiaFrom.leaveTime"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "实际到达:", prop: "arriveTime" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "datetime",
                  format: "yyyy-MM-dd HH:mm:ss",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  placeholder: "选择时间"
                },
                model: {
                  value: _vm.addRecordTripDiaFrom.arriveTime,
                  callback: function($$v) {
                    _vm.$set(_vm.addRecordTripDiaFrom, "arriveTime", $$v)
                  },
                  expression: "addRecordTripDiaFrom.arriveTime"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "驾驶状态：", prop: "drivingState" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.addRecordTripDiaFrom.drivingState,
                    callback: function($$v) {
                      _vm.$set(_vm.addRecordTripDiaFrom, "drivingState", $$v)
                    },
                    expression: "addRecordTripDiaFrom.drivingState"
                  }
                },
                _vm._l(_vm.drivingStateList, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.content, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "变更说明：", prop: "natureState" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.addRecordTripDiaFrom.natureState,
                    callback: function($$v) {
                      _vm.$set(_vm.addRecordTripDiaFrom, "natureState", $$v)
                    },
                    expression: "addRecordTripDiaFrom.natureState"
                  }
                },
                _vm._l(_vm.natureStateList, function(item) {
                  return _c("el-option", {
                    key: item.stateCode,
                    attrs: { label: item.stateValue, value: item.stateCode }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "备注：", prop: "remark" } },
            [
              _c("el-input", {
                attrs: { type: "textarea", rows: 4 },
                model: {
                  value: _vm.addRecordTripDiaFrom.remark,
                  callback: function($$v) {
                    _vm.$set(_vm.addRecordTripDiaFrom, "remark", $$v)
                  },
                  expression: "addRecordTripDiaFrom.remark"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "buttonGroup text-right tabButtonGroup" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "default", size: "small" },
                  on: { click: _vm.cancleTripDia }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.submitTripDia }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }